import { FC, useState } from 'react';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ConnectionSessionFragment } from '../../../../graphql/deserializers/connection-session-deserializer';
import {
  OAuthSessionState,
  OidcSessionState,
  SamlSessionState,
} from '../../../../graphql/generated';
import { LoadingScreen } from '../../../components/loading-screen';
import { usePortalSession } from '../../../components/portal-session-provider/portal-session-provider';

export const INITIATE_SSO_CONNECTION_PARAM = 'initiate-sso-connection';

export const SsoTestConnectionRedirectManager: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { successUrl, recentConnectionSession } = usePortalSession();
  const [searchParams] = useSearchParams();
  const connectionId = searchParams.get(INITIATE_SSO_CONNECTION_PARAM);
  const [shouldRedirectOnSuccess] = useState(
    sessionStorage.getItem('redirectOnSuccess'),
  );
  const sessionIsASuccess = isSsoSessionSuccess(recentConnectionSession);
  const willInitiateTestConnection = !!connectionId;
  const willRedirectToSuccessUrl =
    successUrl && shouldRedirectOnSuccess && sessionIsASuccess;

  React.useLayoutEffect(() => {
    // Start redirection flow:
    // redirect to the api to initiate the sso testing connection
    if (willInitiateTestConnection) {
      sessionStorage.setItem('redirectOnSuccess', 'true');
      window.location.href = `${process.env.NEXT_PUBLIC_API_URL}/sso/authorize/portal?connection=${connectionId}`;
    }
  }, [willInitiateTestConnection, connectionId]);

  React.useLayoutEffect(() => {
    // End redirection flow
    // redirect to the success url at the end of the flow
    if (willRedirectToSuccessUrl) {
      sessionStorage.removeItem('redirectOnSuccess');
      window.location.href = successUrl;
    }
  }, [willRedirectToSuccessUrl, successUrl]);

  // Keep the loading screen while we redirect
  if (willInitiateTestConnection || willRedirectToSuccessUrl) {
    return <LoadingScreen />;
  }
  return <>{children}</>;
};

const isSsoSessionSuccess = (
  ssoConnectionSession?: ConnectionSessionFragment,
) => {
  switch (ssoConnectionSession?.state) {
    case OAuthSessionState.Authorized:
    case OAuthSessionState.Successful:
    case OidcSessionState.Authorized:
    case OidcSessionState.Successful:
    case SamlSessionState.Authorized:
    case SamlSessionState.Successful:
      return true;
    default:
      return false;
  }
};
